import React, { createContext, useContext, useState } from "react";
import { validateAdmin } from "../services/api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (credentials) => {
    const { email, password } = credentials;
    if (loading) return; // Prevent duplicate login attempts
    setLoading(true);
    try {
      const response = await validateAdmin({ email, password });
      if (response?.access_token) {
        localStorage.setItem("access_token", response.access_token);
        setIsAuthenticated(true);
        navigate('/sticker-sup-dupr-admin');
      } else {
        alert("Invalid credentials");
        navigate('/sticker-sup-dupr-admin/login');
      }
    } catch (error) {
      alert("Invalid credentials");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.clear();
    navigate("/sticker-sup-dupr-admin/login");
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, handleLogin, handleLogout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
