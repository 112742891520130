import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useLoading } from '../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { handleLogin } = useAuth();
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const handleLoginClick = async () => {
    if (email && password) {
      setIsLoading(true);
      try {
        await handleLogin({ email, password }); 
        // navigate('/');
      } catch (error) {
        setError('Login failed. Please check your credentials.');
      } finally {
        setIsLoading(false);
      }
    } else {
      setError('Please enter both email and password');
    }
  };

  return (
    <div className="login-container">
      <Box className="overlay">
        <Typography variant="h3" className="tagline">
          Welcome to the Admin Panel
        </Typography>
        <Typography variant="h6" className="subtext">
          Manage WhatsApp Stickers with ease
        </Typography>
      </Box>

      {/* Login Box */}
      <Paper className="login-box" elevation={3} sx={{ bgcolor: 'rgba(255, 255, 255, 0.8)', padding: '2rem' }}>
        <Box textAlign="center" mb={3}>
          <WhatsAppIcon sx={{ fontSize: '4rem', color: '#4CAF50' }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333', mt: 1 }}>
            WhatsApp Sticker Admin
          </Typography>
          <Typography variant="subtitle1" sx={{ color: '#666' }}>
            Admin Login Panel
          </Typography>
        </Box>
        {error && <Typography color="error" textAlign="center" mb={2}>{error}</Typography>}
        <Box display="flex" alignItems="center" mb={2}>
          <EmailIcon sx={{ mr: 1, color: '#4CAF50' }} />
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box display="flex" alignItems="center" mb={3}>
          <LockIcon sx={{ mr: 1, color: '#4CAF50' }} />
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Box>
        <Button
          onClick={handleLoginClick}
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            background: 'linear-gradient(to right, #4CAF50, #81C784)',
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#FFF',
            '&:hover': { background: 'linear-gradient(to right, #388E3C, #66BB6A)' },
          }}
        >
          Login
        </Button>
      </Paper>
    </div>
  );
};

export default Login;
