import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider } from './context/LoadingContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
  <AuthProvider>
    <LoadingProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </LoadingProvider>
  </AuthProvider>
</Router>
);
